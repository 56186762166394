import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  value: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createBlock(_component_Field, {
    name: _ctx.name,
    as: "select",
    class: _normalizeClass(_ctx.clazz),
    onChange: _ctx.onSelect,
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      (_ctx.noSelected)
        ? (_openBlock(), _createElementBlock("option", _hoisted_1))
        : _createCommentVNode("", true),
      (_ctx.customOption)
        ? _renderSlot(_ctx.$slots, "custom", { key: 1 })
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, i) => {
        return _renderSlot(_ctx.$slots, "default", {
          key: i,
          option: item
        })
      }), 128))
    ]),
    _: 3
  }, 8, ["name", "class", "onChange", "modelValue", "disabled"]))
}