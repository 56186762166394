
import {Field} from 'vee-validate'
import {defineComponent, ref, watch} from "vue";

export default defineComponent({

  name: "BaseSelect",
  components: {
    Field
  },
  props: {
    clazz: {type: String, default: 'form-select form-select-solid form-select-lg fw-bold'},
    name: {type: String, required: true},
    modelValue: {},
    noSelected: {required: false, default: false},
    data: {type: Array, required: true},
    customOption: {required: false, default: false},
    disabled:{default: false},
  },
  emits: ['update:modelValue', 'onSelected', 'onSelectedText'],
  setup(props) {
    const model = ref(props.modelValue);
    watch(() => props.modelValue, (cb) => {
      model.value = cb
    })
    return {
      model
    }
  },
  methods: {
    onSelect(event) {
      this.$emit('update:modelValue', event.target.value)
      this.$emit('onSelected', event.target.value)
      this.$emit('onSelectedText', event.target.value)
    }
  }
})
